import React, { useEffect, useState } from 'react';
import './App.css';
import Map from './Map';
import Form from './Form';
import Table from './Table';
import Stats from './Stats';

function App() {
  const [data, setData] = useState([]);

  const year = window.year;
  const fetchData = () => fetch(`/api/entries/?year=${year}`)
    .then(response => response.json())
    .then(jsonData => setData(jsonData));

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <Map data={data} />
      <main>
        <h1>Půjdem spolu do Betléma</h1>
        <p className="previous-years">
          <a href="/?year=2023">2023</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/?year=2022">2022</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="/?year=2021">2021</a>
        </p>
        <Form setData={setData} />
        <div class="row">
          <Stats data={data} />
          <Table data={data} />
        </div>
      </main>
    </div>
  );
}

export default App;
