import React, { useEffect, useState } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Marker } from "react-google-maps";
import directions from './directions.json';
import mapStyle from './mapStyles/brown.json';

const apiKey = 'AIzaSyDHYSHo-BDxcVLOfiCb8Zf74fhZDJs22zs';

const brno = { lat: 49.1994653, lng: 16.6057441 };

const MapWidget = withScriptjs(withGoogleMap(({ marker }) => {
    return (
        <div>
            <GoogleMap
                defaultZoom={8}
                defaultCenter={marker}
                defaultOptions={{ styles: mapStyle }}
            >
                <DirectionsRenderer directions={directions} />
                <Marker position={marker} icon="https://maps.google.com/mapfiles/kml/paddle/wht-stars.png" />
            </GoogleMap>
        </div>
    )
}))

const Map = ({ data }) => {
    const [marker, setMarker] = useState(brno);

    useEffect(() => {
        const totalDistInMeters = data.reduce((acc, entry) => acc + entry.dist, 0) * 1000;

        const steps = directions.routes[0].legs[0].steps;
        let stepsSum = 0;
        for (const step of steps) {
            stepsSum += step.distance.value;
            if (stepsSum > totalDistInMeters) {
                setMarker(step.start_location);console.log(step)
                break;
            }
        }
    }, [data])

    return (
        <MapWidget
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `480px`, width: `100%` }} />}
            mapElement={<div style={{ height: `100%`, width: `100%` }} />}
            marker={marker}
        />
    );
}

export default Map
