import React from 'react'

const distance = 3273624;


const Stats = ({ data }) => {
    const userSums = data
        .reduce((acc, { name, dist }) => {
            acc[name] = acc[name] ? acc[name] += dist : dist;
            return acc;
        }, {});
    const sortedUserSums = Object.entries(userSums)
        .sort(([,a],[,b]) => b - a)
        .map(([name, dist]) => ({ name, dist }));

    const sum = sortedUserSums.reduce((acc, { dist }) => acc + dist, 0);

    let remaining = (distance / 1000) - sum;
    remaining = remaining > 0
        ? remaining
        : 0;

    const time = data.length
        ? Number(data[data.length - 1].created) - Number(data[0].created)
        : 1;
    const avgSpeed = sum / time;
    const now = new Date().getTime();
    const eta = new Date(now + remaining/avgSpeed);

    return (
        <section className="stats-wrapper">
            <div className="row">
                <div className="col">
                    <div className="subtitle">Ušlá vzdálenost celkem</div>
                    <h3>{sum.toFixed(2)} km</h3>
                    <div className="subtitle">Počet účastníků</div>
                    <h3>{sortedUserSums.length}</h3>
                </div>
                <div className="col">
                    <div className="subtitle">Zbývá</div>
                    <h3>{remaining.toFixed(2)} km</h3>
                    <div className="subtitle">ETA</div>
                    <h3>{eta.toLocaleDateString('cs')}</h3>
                </div>
            </div>

            <h3 style={{ marginTop: 40, marginBottom: 10 }}>Leaderboard</h3>
            <table>
                <thead>
                    <tr>
                        <th>Jméno</th>
                        <th style={{ textAlign: 'center' }}>Celkem (km)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUserSums.map(({  name, dist, }) => (
                        <tr key={`${name}`}>
                            <td>{name}</td>
                            <td className="dist">{dist.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}

export default Stats
