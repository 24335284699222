import React from 'react'

const Table = ({ data }) => {
    const reversedData = [...data].reverse();

    return (
        <section class="table-wrapper">
            <h3 style={{ marginBottom: 10 }}>Log</h3>
            <table>
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Jméno</th>
                        <th style={{ textAlign: 'right' }}>Ušlá vzdálenost (km)</th>
                    </tr>
                </thead>
                <tbody>
                    {reversedData.map(({ created, name, dist,  }) => (
                        <tr key={`${created}.${name}`}>
                            <td>{new Date(parseInt(created)).toLocaleDateString('cs')}</td>
                            <td>{name}</td>
                            <td class="dist">{dist.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}

export default Table
