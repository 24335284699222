import React, { useState } from 'react'

const Form = ({ setData }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [name, setName] = useState(localStorage.getItem('name') || '');
    const [dist, setDist] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        const distNum = parseFloat(dist.replace(',', '.'));

        if (name.length && distNum > 0) {
            fetch('/api/entries', {
                method: 'POST',
                body: JSON.stringify({ name: name.substr(0, 64), dist: distNum }),
            })
                .then((response) => {
                    if (response.ok) {
                        response.json().then(jsonData => {
                            setIsSubmitted(true);
                            setData(jsonData);
                            localStorage.setItem('name', name);
                        })
                    } else {
                        alert('Chyba! ' + response.statusText || response.status)
                    }
                });
        } else {
            alert('Fuj! Dej jméno a kladné číslo')
        }
    }

    if (isSubmitted) {
        return <section>Děkujeme za příspěvek!</section>
    }

    return (
        <>
        <section className="form-wrapper" style={{ marginBottom: 80 }}>
            <form onSubmit={onSubmit}>
                <input name='name' value={name} placeholder='Jméno' onChange={e => setName(e.target.value)} />
                <input name='dist' value={dist} placeholder='Ušlá vzdálenost (km)' onChange={e => setDist(e.target.value)} />
                <button>Přidat</button>
            </form>
            <div className="watch-shortcut">
                nebo automatizuj s <a href="https://www.icloud.com/shortcuts/8b0ce8ed31304a8e9955166aaaa5392f" target="_blank" rel="noreferrer">
                    ⌚ Apple Health Shortcut
                </a> by @peter.siman
            </div>
        </section>
        </>
    )
}

export default Form
